import { useState } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import theme, { COLOR, FONTS, SECONDARY_COLOR, SHADOW } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import axios from 'axios'
import { styled } from '@mui/material/styles'
import sanitize from 'src/common/utils/js/sanitize'
import Button from 'src/common/components/button/Button'

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: rem(40),
  },
  drawerTitleContainer: {
    padding: `${rem(16)} ${rem(24)}`,
    background: COLOR.LIGHT_BACKGROUND,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    [theme.breakpoints.up('md')]: {
      paddingLeft: rem(40),
      paddingRight: rem(40),
    },
  },
  drawerTitle: {
    color: theme.palette.primary.dark, // Replace with your custom color or use the default as fallback
    fontFamily: FONTS.ROC_GROTESK,
    fontSize: rem(24),
    fontWeight: 500,
    lineHeight: rem(22),
    letterSpacing: '0.48px',
    textTransform: 'uppercase',
  },
  newsletterContainer: {
    padding: `${rem(24)} ${rem(24)} ${rem(32)} ${rem(24)}`,
    display: 'flex',
    flexDirection: 'column',
    gap: rem(24),
    alignSelf: 'stretch',
    [theme.breakpoints.up('md')]: {
      paddingLeft: rem(40),
      paddingRight: rem(40),
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${rem(24)} 0`,
    },
  },
  newsletterContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    alignSelf: 'stretch',
  },
  newsletterInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    alignSelf: 'stretch',
  },
  newsletterContentTitle: {
    color: theme.palette.primary.dark,
    fontFamily: FONTS.ROC_GROTESK,
    fontSize: rem(40),
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: rem(36),
    textTransform: 'uppercase',
  },
  newsletterContentDescription: {
    color: COLOR.BLACK,
    fontFamily: FONTS.TABLET_GOTHIC,
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: '130%',
  },
  newsLetterInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0',
    gap: rem(4),
    width: '100%',
    flex: 'none',
    order: '1',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    width: '100%',
    flex: 'none',
    order: '0',
    aligSelf: 'stretch',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  emailInput: {
    width: '100%',
    marginTop: rem(16),
    borderRadius: rem(5),
    background: theme.palette.presidio.color.NEAR_WHITE,
    borderColor: theme.palette.presidio.color.DARK_GRAY,
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  emailButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${rem(5)} ${rem(32)} ${rem(7)}`,
    gap: rem(8),
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',
  },
  headSection: {
    ...theme.typography.h3,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.02em',
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
  },
  errorMessage: {
    ...theme.typography.helperText,
    minHeight: rem(23),
    padding: `${rem(8)} 0 0 ${rem(10)} `,
    paddingBottom: 16,
    color: theme.palette.error.dark,
  },
  messageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    flex: 'none',
    order: '1',
    flexGrow: '1',
    alignSelf: 'center',
    border: `1px solid ${SECONDARY_COLOR.LIGHT[80]}`,
    padding: '16px 24px',
    borderRadius: '16px',
    background: SECONDARY_COLOR.LIGHT[60],
    '& div:nth-of-type(1)': {
      ...theme.typography.body.bold,
      color: theme.palette.primary.dark,
      marginBottom: rem(8),
    },
    '& div:nth-of-type(2)': {
      ...theme.typography.body.default,
      color: COLOR.BLACK,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      alignSelf: 'center',
    },
  },
}))

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: `${theme.palette.presidio.color.MID_GRAY} !important`,
  },
  '& label.MuiFormLabel-root': {
    color: theme.palette.presidio.color.DARK_GRAY,
    fontFamily: FONTS.TABLET_GOTHIC,
  },
  '& label.Mui-error': {
    border: `0px solid ${theme.palette.error.dark} !important`,
    color: `${theme.palette.presidio.color.MID_GRAY} !important`,
  },

  '& .MuiFilledInput-root': {
    border: `1px solid ${theme.palette.presidio.color.DARK_GRAY}`,
    backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
    fontFamily: FONTS.TABLET_GOTHIC,
    fontSize: rem(16),
    borderRadius: '5px',
    '&:after': {
      border: `0px solid ${theme.palette.presidio.color.DARK_GRAY} !important`,
      transform: `translate(10px, 8px) scale(0.85)`,
    },
    '&:before': {
      border: `0px solid ${theme.palette.presidio.color.DARK_GRAY} !important`,
    },
    '&:hover': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
    },
    '&:focus-within': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
  },
  '& .Mui-error': {
    border: `1px solid ${theme.palette.error.dark} !important`,
    backgroundColor: theme.palette.error.light,
    '&:focus-within': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.dark} !important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
})

export default function NewsletterMenu(props) {
  const { classes } = useStyles()

  const { footerData, drawerTitle } = props

  if (!footerData || Object.keys(footerData).length === 0) return null

  const { footer, newsletterMailingId } = footerData
  const newsletterData = footer?.news_letter || []

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)

  const filteredNewsLetterData = newsletterData.filter(
    (x) => x.default_mailing_list === true
  )[0]

  if (!filteredNewsLetterData) return null

  const emailValidation = async (event) => {
    const validRegex =
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,}$/

    if (email.match(validRegex)) {
      try {
        const res = await axios.get(
          `/api/mailchimp?email=${email}&audienceId=${newsletterMailingId}`
        )
        if (res.status === 200) {
          setMessage('Fulfilled')
        }
      } catch (e) {
        console.error(e)
        setError(true)
        if (e?.response?.data?.title) {
          setMessage(e?.response?.data?.title)
        } else {
          setMessage('something went wrong try again')
        }
      }
    } else {
      setError(true)
      setMessage(filteredNewsLetterData?.footer_newsletter_errortext)
    }
  }

  const inputId = `filled-basic-${Math.random().toString(36).substring(7)}`

  return (
    <Box className={classes.container} data-weglot="translate">
      {drawerTitle && (
        <Box className={classes.drawerTitleContainer}>
          <Typography className={classes.drawerTitle}>{drawerTitle}</Typography>
        </Box>
      )}
      <Box className={classes.newsletterContainer}>
        <Box className={classes.newsletterContentContainer}>
          <Typography
            className={classes.newsletterContentTitle}
            id="newsletter-title"
          >
            {filteredNewsLetterData.footer_news_title}
          </Typography>
          <Typography
            className={classes.newsletterContentDescription}
            id="newsletter-description"
          >
            {filteredNewsLetterData.footer_news_description}
          </Typography>
        </Box>
        <Box className={classes.newsletterInputContainer}>
          {message === 'Fulfilled' ? (
            <div className={classes.messageBlock}>
              <div>
                {sanitize(
                  filteredNewsLetterData.footer_newsletter_thankyoutext
                )}
              </div>
            </div>
          ) : (
            <div className={classes.newsLetterInput}>
              <div className={classes.textField}>
                <CssTextField
                  error={error}
                  className={classes.emailInput}
                  InputProps={{
                    classes: {
                      inputProps: {
                        'aria-labelledby': inputId,
                      },
                      input: {
                        WebkitBoxShadow: '0 0 0 1000px white inset !important',
                      },
                    },
                  }}
                  InputLabelProps={{
                    htmlFor: inputId,
                    ariaLabel: 'Email Address',
                  }}
                  id={inputId}
                  label="Email Address"
                  variant="filled"
                  onKeyDown={(e) => {
                    if (!error && e?.key === 'Enter') {
                      emailValidation()
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length < 1 || e.target.value !== email) {
                      setError(false)
                    }
                    setEmail(e.target.value)
                  }}
                />

                <div className={classes.errorMessage}>
                  {error ? message : ''}
                </div>
              </div>
              <Button
                disabled={error}
                onClick={emailValidation}
                className={classes.emailButton}
              >
                Sign Up
              </Button>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  )
}
