import { useEffect, useState } from 'react'
import axios from 'axios'
import { TextField, Card, CardContent, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import PropTypes from 'prop-types'
import theme, { SHADOW, COLOR, FONTS } from 'src/styles/theme'
import Button from 'src/common/components/button/Button'
import { rem } from 'src/common/utils/css/index'
import sanitize from 'src/common/utils/js/sanitize'
import MailIcon from 'src/components/icons/Mail2'

const useStyles = makeStyles()((_theme) => ({
  newsLetter: {
    maxWidth: '85.20%',
    minHeight: rem(275),
    margin: `${rem(-138)} auto`,
    backgroundColor: theme.palette.presidio.color.BAKER_BEACH_WHITE,
    zIndex: 1,
    position: 'relative',
    boxShadow: SHADOW.LEVEL_FIVE,
    [theme.breakpoints.up('md')]: {
      maxWidth: '88.88%',
      minHeight: rem(248),
      margin: `${rem(-124)} auto`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      maxWidth: '76.36%',
      minHeight: rem(174),
      margin: `${rem(-87)} auto`,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '78.33%',
      minHeight: rem(178),
      margin: `${rem(-100)} auto`,
    },
  },
  newsLetterCon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${rem(24)} ${rem(80)} ${rem(24)} ${rem(80)}`,
      width: '100%',
      height: rem(174),
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      height: rem(200),
      padding: `${rem(32)} ${rem(96)} ${rem(24)}`,
    },
  },
  newsLetterInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: rem(24),
    flex: 'none',
    order: '0',
    flexGrow: '0',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      gap: rem(18),
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0',
      gap: rem(24),
    },
    [theme.breakpoints.up('xl')]: {
      gap: rem(120),
    },
  },
  newsletterContentContainer: {
    display: 'flex',
    gap: rem(8),
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: rem(16),
    },
  },
  newsletterContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      gap: rem(16),
      alignItems: 'unset',
      justifyContent: 'unset',
    },
  },
  newsLetterInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0',
    gap: rem(4),
    width: '100%',
    flex: 'none',
    order: '1',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      gap: rem(4),
      width: '40.22%',
      height: rem(118),
    },
    [theme.breakpoints.up('xl')]: {
      gap: rem(4),
      width: '38.46%',
      height: rem(122),
    },
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    width: '100%',
    flex: 'none',
    order: '0',
    aligSelf: 'stretch',
    flexGrow: '0',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  emailInput: {
    width: '100%',
    marginTop: rem(16),
    borderRadius: rem(5),
    background: theme.palette.presidio.color.NEAR_WHITE,
    borderColor: theme.palette.presidio.color.DARK_GRAY,
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  emailButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${rem(5)} ${rem(32)} ${rem(7)}`,
    gap: rem(8),
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',
  },
  cardContent: {
    minHeight: 'auto',
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      display: 'block',
      minHeight: rem(174),
      backgroundPosition: 'initial',
      backgroundSize: '17% auto',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundSize: '17% auto',
      minHeight: rem(200),
    },
  },
  headSection: {
    ...theme.typography.h3,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  backgroundImageLayer: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    height: '250%',
    width: '17%',
    zIndex: 0,
    opacity: '1',
    [theme.breakpoints.up('lg')]: {
      width: '15%',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: rem(180),
    },
  },
  paragraphSection: {
    ...theme.typography.body.default,
    color: theme.palette.presidio.color.BLACK,
    letterSpacing: '0',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  errorMessage: {
    ...theme.typography.helperText,
    minHeight: rem(23),
    padding: `${rem(8)} 0 0 ${rem(10)} `,
    paddingBottom: 0,
    color: theme.palette.error.dark,
  },
  messageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0',
    gap: rem(4),
    width: '100%',
    flex: 'none',
    order: '1',
    flexGrow: '1',
    alignSelf: 'center',
    paddingTop: rem(50),
    '& div:nth-of-type(1)': {
      ...theme.typography.body.bold,
      color: theme.palette.primary.dark,
    },
    '& div:nth-of-type(2)': {
      ...theme.typography.body.default,
      color: COLOR.BLACK,
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      alignSelf: 'center',
      paddingTop: rem(40),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      gap: rem(4),
      width: '40.22%',
      alignSelf: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      gap: rem(4),
      width: '38.46%',
    },
  },
}))

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: `${theme.palette.presidio.color.MID_GRAY} !important`,
  },
  '& label.MuiFormLabel-root': {
    color: theme.palette.presidio.color.DARK_GRAY,
    fontFamily: FONTS.TABLET_GOTHIC,
  },
  '& label.Mui-error': {
    border: `0px solid ${theme.palette.error.dark} !important`,
    color: `${theme.palette.presidio.color.MID_GRAY} !important`,
  },

  '& .MuiFilledInput-root': {
    border: `1px solid ${theme.palette.presidio.color.DARK_GRAY}`,
    backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
    fontFamily: FONTS.TABLET_GOTHIC,
    fontSize: rem(16),
    borderRadius: '5px',
    '&:after': {
      border: `0px solid ${theme.palette.presidio.color.DARK_GRAY} !important`,
      transform: `translate(10px, 8px) scale(0.85)`,
    },
    '&:before': {
      border: `0px solid ${theme.palette.presidio.color.DARK_GRAY} !important`,
    },
    '&:hover': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
    },
    '&:focus-within': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.presidio.color.NEAR_WHITE,
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.presidio.color.DARK_GRAY,
    },
  },
  '& .Mui-error': {
    border: `1px solid ${theme.palette.error.dark} !important`,
    backgroundColor: theme.palette.error.light,
    '&:focus-within': {
      boxShadow: SHADOW.LEVEL_THREE,
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.dark} !important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
})

function NewsLetter(props) {
  const { NewsLetterData, newsletterMailingId } = props

  const smUp = useMediaQuery(() => theme.breakpoints.up('sm'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const { classes } = useStyles()

  if (!NewsLetterData) return null

  let filteredNewsLetterData = NewsLetterData.filter(
    (x) => x.mailing_list_id === newsletterMailingId
  )[0]

  if (filteredNewsLetterData === undefined) {
    // eslint-disable-next-line prefer-destructuring
    filteredNewsLetterData = NewsLetterData.filter(
      (x) => x.default_mailing_list === true
    )[0]
  }

  const emailValidation = async (event) => {
    const validRegex =
      /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,}$/

    if (email.match(validRegex)) {
      try {
        const res = await axios.get(
          `/api/mailchimp?email=${email}&audienceId=${newsletterMailingId}`
        )
        if (res.status === 200) {
          setMessage('Fulfilled')
        }
      } catch (e) {
        console.error(e)
        setError(true)
        if (e?.response?.data?.title) {
          setMessage(e?.response?.data?.title)
        } else {
          setMessage('something went wrong try again')
        }
      }
    } else {
      setError(true)
      setMessage(filteredNewsLetterData?.footer_newsletter_errortext)
    }
  }

  useEffect(() => {
    const newsLetter = document.querySelector('[data-id="newsletter"]')
    if (newsLetter) {
      const height = newsLetter.clientHeight
      const margin = (-1 * height) / 2
      newsLetter.style.margin = `${rem(margin)} auto`
    }
  }, [smUp, mdUp, lgUp, xlUp])

  return (
    <Card
      className={classes.newsLetter}
      data-weglot="translate"
      data-id="newsletter"
    >
      <CardContent
        sx={{ p: 0, '&:last-child': { pb: 0 } }}
        className={classes.cardContent}
      >
        <div className={classes.newsLetterCon}>
          <div className={classes.newsLetterInnerContainer}>
            <div className={classes.newsletterContentContainer}>
              <div>
                <MailIcon />
              </div>
              <div className={classes.newsletterContent}>
                <div className={classes.headSection}>
                  {filteredNewsLetterData?.footer_news_title}
                </div>
                <div className={classes.paragraphSection}>
                  {filteredNewsLetterData?.footer_news_description}
                </div>
              </div>
            </div>
            {message === 'Fulfilled' ? (
              <div className={classes.messageBlock}>
                <div>
                  {sanitize(
                    filteredNewsLetterData?.footer_newsletter_thankyoutext
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.newsLetterInput}>
                <div className={classes.textField}>
                  <CssTextField
                    error={error}
                    className={classes.emailInput}
                    InputProps={{
                      classes: {
                        input: {
                          WebkitBoxShadow:
                            '0 0 0 1000px white inset !important',
                        },
                      },
                    }}
                    id="filled-basic"
                    label="Email Address"
                    variant="filled"
                    onKeyDown={(e) => {
                      if (!error && e?.key === 'Enter') {
                        emailValidation()
                      }
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length < 1 ||
                        e.target.value !== email
                      ) {
                        setError(false)
                      }
                      setEmail(e.target.value)
                    }}
                  />

                  <div className={classes.errorMessage}>
                    {error ? message : ''}
                  </div>
                </div>
                <Button
                  disabled={error}
                  onClick={emailValidation}
                  className={classes.emailButton}
                >
                  Sign Up
                </Button>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default NewsLetter

NewsLetter.propTypes = {
  NewsLetterData: PropTypes.arrayOf(
    PropTypes.shape({
      footer_news_title: PropTypes.string,
      footer_news_description: PropTypes.string,
      footer_newsletter_thankyoutext: PropTypes.string,
      footer_newsletter_errortext: PropTypes.string,
      api: PropTypes.string,
    })
  ).isRequired,
}
